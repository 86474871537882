import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SecurityIcon from '@mui/icons-material/Security';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QrCodeIcon from '@mui/icons-material/QrCode2';

// Styled components
const HeaderRow = styled(Box)({
  backgroundColor: '#ffe600',
  width: '100%',
  height: '55px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  position: 'fixed',
  top: 0,
  zIndex: 1000,
});

const Logo = styled('img')({
  height: '34px',
  marginLeft: '24px',
});

const MainContainer = styled(Container)({
  width: '950px',
  height: '460px',
  padding: '20px',
  marginTop: '24px',
  backgroundColor: '#fff',
});

const ShippingContainer = styled(Container)({
  maxWidth: '1220px !important',
  padding: '32px 16px',
  backgroundColor: '#eeeeee',
});

const FormContainer = styled(Box)({
  padding: '32px',
  border: '1px solid #e5e5e5',
  borderRadius: '6px',
  backgroundColor: '#fff',
});

const ShippingFormContainer = styled(Box)({
  padding: '32px',
  backgroundColor: '#fff',
  borderRadius: '6px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, .1)',
  marginTop: '16px',
});

const CustomInputLabel = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '13px',
  color: '#333',
  marginBottom: '4px'
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    minHeight: '48px',
    '& fieldset': {
      borderColor: '#bfbfbf',
      borderRadius: '0.375em',
    },
    '& input': {
      padding: '13px 13px',
      fontFamily: 'Inter, sans-serif',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
});

const DisabledTextField = styled(StyledTextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(0, 0, 0, .02)',
    '& fieldset': {
      borderStyle: 'dashed',
      borderColor: 'rgba(0, 0, 0, .25)',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, .02)',
  },
});

const ContinueButton = styled(Button)({
  backgroundColor: '#3483fa',
  color: '#fff',
  padding: '12px',
  textTransform: 'none',
  fontFamily: 'Inter, sans-serif',
  marginTop: '24px',
  '&:hover': {
    backgroundColor: '#2968c8',
  },
});

const SecurityLink = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#3483fa',
  cursor: 'pointer',
  marginTop: 'auto',
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
  },
  '&:hover': {
    color: '#2968c8',
  },
});

const OrderSummaryContainer = styled(Box)({
  backgroundColor: '#f5f5f5',
  padding: '48px',
  height: '100%',
});

const Divider = styled('hr')({
  margin: '20px 0',
  border: 'none',
  borderTop: '1px solid #0000001a',
});

const SummaryRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: 'Inter, sans-serif',
});

const TotalRow = styled(SummaryRow)({
  fontSize: '18px',
  fontWeight: 700,
});

const ShippingOptionsContainer = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '6px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,.12)',
});

const ShippingHeader = styled(Box)({
  padding: '12px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ProductImage = styled('img')({
  width: 40,
  height: 40,
  borderRadius: '50%',
  objectFit: 'cover',
});

const RadioOption = styled(Box)({
  padding: '20px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const EmailStepContainer = styled(Box)({
  minHeight: 'calc(100vh)',
  backgroundColor: '#fff',
  marginTop: '55px'
});

const OtherStepsContainer = styled(Box)({
  minHeight: 'calc(100vh)',
  backgroundColor: '#eeeeee',
  marginTop: '55px',
  display: 'flex',
  justifyContent: 'center'
});

const PaymentOptionContainer = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '6px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,.12)',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
});

const PaymentOptionHeader = styled(Box)({
  padding: '24px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '73px',
});

const PaymentMethodInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: '100%'
});

const PaymentLabel = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
});

const InstantApproval = styled(Typography)({
  fontSize: '12px',
  color: '#00a650',
  marginLeft: '8px',
});

const PaymentOptionsContainer = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '6px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,.12)',
});

const PaymentFormContainer = styled(Box)({
  padding: '24px 32px',
  backgroundColor: '#fff',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
});

const FormInputContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const MercadoLivreCheckout = ({ cart }) => {
  const [email, setEmail] = useState('');
  const [currentStep, setCurrentStep] = useState('email');
  const [selectedPayment, setSelectedPayment] = useState('credit_card');
  const [shippingData, setShippingData] = useState({
    fullName: '',
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: '',
    phone: '',
  });

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setCurrentStep('shipping');
  };

  const handleShippingSubmit = (e) => {
    e.preventDefault();
    setCurrentStep('shippingOptions');
  };

  const handleShippingOptionSubmit = () => {
    setCurrentStep('payment');
  };

  const handleInputChange = (field) => (e) => {
    setShippingData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const getDeliveryDates = () => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() + 3);
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 5);
    
    const formatDate = (date) => {
      return date.toLocaleDateString('pt-BR', { 
        day: 'numeric',
        month: 'numeric'
      });
    };
    
    return {
      start: formatDate(startDate),
      end: formatDate(endDate)
    };
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const renderEmailStep = () => (
    <MainContainer maxWidth={false}>
      <Box display="flex" gap={4}>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography
            variant="h4"
            sx={{
              fontSize: '32px',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              color: '#000',
              marginBottom: 3,
            }}
          >
            Olá! Para comprar, digite seu e-mail ou telefone
          </Typography>
          
          <SecurityLink>
            <SecurityIcon />
            <Typography
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
              }}
            >
              Tenho um problema de segurança
            </Typography>
          </SecurityLink>
        </Box>

        <Box flex={1}>
          <FormContainer>
            <form onSubmit={handleEmailSubmit}>
              <CustomInputLabel>E-mail ou telefone</CustomInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <ContinueButton
                fullWidth
                variant="contained"
                type="submit"
              >
                Continue
              </ContinueButton>
            </form>
          </FormContainer>
        </Box>
      </Box>
    </MainContainer>
  );

  const renderShippingStep = () => {
    // Calculate total
    const productPrice = parseFloat(cart?.total || 0);
    const shippingCost = 15.29;
    const total = productPrice + shippingCost;

    return (
      <ShippingContainer maxWidth={false}>
        <Grid container spacing={4} sx={{ maxWidth: '1220px' }}>
          <Grid item xs={12} md={8} sx={{ 
            paddingTop: '50px !important',
            paddingLeft: '30px !important'
          }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: '22px',
                fontWeight: 600,
                fontFamily: 'Inter, sans-serif',
                color: '#000',
                padding: '10px 0px'
              }}
            >
              Escolha a forma de entrega
            </Typography>

            <ShippingFormContainer>
              <form onSubmit={handleShippingSubmit}>
                <Box mb={3}>
                  <CustomInputLabel>Nome completo</CustomInputLabel>
                  <StyledTextField
                    value={shippingData.fullName}
                    onChange={handleInputChange('fullName')}
                    sx={{ width: '60%' }}
                  />
                </Box>

                <Box mb={3}>
                  <CustomInputLabel>CEP</CustomInputLabel>
                  <StyledTextField
                    value={shippingData.cep}
                    onChange={handleInputChange('cep')}
                    sx={{ width: '47%' }}
                  />
                </Box>

                <Grid container spacing={2} mb={3}>
                  <Grid item xs={6}>
                    <CustomInputLabel>Estado</CustomInputLabel>
                    <DisabledTextField
                      value={shippingData.state}
                      onChange={handleInputChange('state')}
                      disabled
                      sx={{ width: '96%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputLabel>Cidade</CustomInputLabel>
                    <DisabledTextField
                      value={shippingData.city}
                      onChange={handleInputChange('city')}
                      disabled
                      sx={{ width: '96%' }}
                    />
                  </Grid>
                </Grid>

                <Box mb={3}>
                  <CustomInputLabel>Bairro</CustomInputLabel>
                  <StyledTextField
                    value={shippingData.neighborhood}
                    onChange={handleInputChange('neighborhood')}
                    sx={{ width: '47%' }}
                  />
                </Box>

                <Grid container spacing={2} mb={3}>
                  <Grid item xs={6}>
                    <CustomInputLabel>Endereço</CustomInputLabel>
                    <StyledTextField
                      value={shippingData.street}
                      onChange={handleInputChange('street')}
                      sx={{ width: '96%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputLabel>Número</CustomInputLabel>
                    <StyledTextField
                      value={shippingData.number}
                      onChange={handleInputChange('number')}
                      sx={{ width: '96%' }}
                    />
                  </Grid>
                </Grid>

                <Box mb={3}>
                  <CustomInputLabel>Complemento (opcional)</CustomInputLabel>
                  <StyledTextField
                    value={shippingData.complement}
                    onChange={handleInputChange('complement')}
                    sx={{ width: '47%' }}
                  />
                </Box>

                <Box mb={3}>
                  <CustomInputLabel>Telefone de contato</CustomInputLabel>
                  <StyledTextField
                    value={shippingData.phone}
                    onChange={handleInputChange('phone')}
                    sx={{ width: '47%' }}
                  />
                </Box>
              </form>
            </ShippingFormContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0 }}>
              <ContinueButton
                variant="contained"
                type="submit"
                onClick={handleShippingSubmit}
                sx={{ width: '120px' }}
              >
                Continuar
              </ContinueButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ 
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            maxWidth: '412px !important'
          }}>
            <OrderSummaryContainer>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: 600,
                  fontFamily: 'Inter, sans-serif',
                  color: '#000',
                }}
              >
                Resumo da compra
              </Typography>

              <Divider />

              <SummaryRow sx={{ mb: 2 }}>
                <Typography>Produto</Typography>
                <Typography>R$ {productPrice.toFixed(2)}</Typography>
              </SummaryRow>

              <SummaryRow>
                <Typography>Frete</Typography>
                <Typography>R$ 15,29</Typography>
              </SummaryRow>

              <Divider />

              <TotalRow>
                <Typography>Você pagará</Typography>
                <Typography>R$ {total.toFixed(2)}</Typography>
              </TotalRow>
            </OrderSummaryContainer>
          </Grid>
        </Grid>
      </ShippingContainer>
    );
  };

  const renderShippingOptionsStep = () => {
    const deliveryDates = getDeliveryDates();
    
    return (
      <Grid container spacing={4} sx={{ 
        marginLeft: '0px !important', 
        marginTop: '0px !important',
        maxWidth: '1220px'
      }}>
        <Grid item xs={12} md={8} sx={{ 
          paddingTop: '50px !important',
          paddingLeft: '30px !important'
        }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '22px',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              color: '#000',
              padding: '10px 0px'
            }}
          >
            Escolha quando seu pedido chegará
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOnIcon sx={{ fontSize: 16, mr: 1, color: '#666' }} />
            <Typography sx={{ 
              fontSize: '13px',
              color: '#666',
              fontFamily: 'Inter, sans-serif'
            }}>
              Entrega para {shippingData.street} {shippingData.number}
            </Typography>
          </Box>

          <ShippingOptionsContainer>
            <ShippingHeader>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ 
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'Inter, sans-serif',
                  mr: 1
                }}>
                  1 envio
                </Typography>
                <img 
                  src="https://http2.mlstatic.com/frontend-assets/bf-ui-library/3.42.0/assets/icons/full.svg" 
                  alt="shipping"
                  style={{ width: 50, height: 16 }}
                />
              </Box>
              <ProductImage src="https://http2.mlstatic.com/frontend-assets/bf-ui-library/3.42.0/assets/icons/full.svg" alt="product" />
            </ShippingHeader>
            
            <Divider sx={{ border: 'none', borderTop: '1px solid #e6e6e6', margin: 0 }} />
            
            <RadioGroup defaultValue="tomorrow">
              <RadioOption>
                <FormControlLabel
                  value="tomorrow"
                  control={<Radio />}
                  label={
                    <Typography sx={{ 
                      fontSize: '14px',
                      fontFamily: 'Inter, sans-serif'
                    }}>
                      Amanhã
                    </Typography>
                  }
                />
                <Typography sx={{ 
                  fontSize: '14px',
                  fontFamily: 'Inter, sans-serif'
                }}>
                  R$ 15,30
                </Typography>
              </RadioOption>
              
              <Divider sx={{ border: 'none', borderTop: '1px solid #e6e6e6', margin: 0 }} />
              
              <RadioOption>
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label={
                    <Typography sx={{ 
                      fontSize: '14px',
                      fontFamily: 'Inter, sans-serif'
                    }}>
                      Entre {deliveryDates.start} e {deliveryDates.end}
                    </Typography>
                  }
                />
                <Typography sx={{ 
                  fontSize: '14px',
                  fontFamily: 'Inter, sans-serif'
                }}>
                  Grátis
                </Typography>
              </RadioOption>
            </RadioGroup>
          </ShippingOptionsContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <ContinueButton
              variant="contained"
              onClick={handleShippingOptionSubmit}
              sx={{ width: '120px' }}
            >
              Continuar
            </ContinueButton>
          </Box>
        </Grid>
        
        <Grid item xs={12} md={4} sx={{ 
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
          maxWidth: '412px !important',
          marginLeft: '0px !important',
          marginTop: '0px !important'
        }}>
          <OrderSummaryContainer>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 600,
                fontFamily: 'Inter, sans-serif',
                color: '#000',
              }}
            >
              Resumo da compra
            </Typography>

            <Divider />

            <SummaryRow sx={{ mb: 2 }}>
              <Typography>Produto</Typography>
              <Typography>R$ {cart.total.toFixed(2)}</Typography>
            </SummaryRow>

            <SummaryRow>
              <Typography>Frete</Typography>
              <Typography>R$ 15,29</Typography>
            </SummaryRow>

            <Divider />

            <TotalRow>
              <Typography>Você pagará</Typography>
              <Typography>R$ {(parseFloat(cart.total) + 15.29).toFixed(2)}</Typography>
            </TotalRow>
          </OrderSummaryContainer>
        </Grid>
      </Grid>
    );
  };

  const renderPaymentStep = () => {
    return (
      <Grid container spacing={4} sx={{ 
        marginLeft: '0px !important', 
        marginTop: '0px !important',
        maxWidth: '1220px'
      }}>
        <Grid item xs={12} md={8} sx={{ 
          paddingTop: '50px !important',
          paddingLeft: '30px !important'
        }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '22px',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              color: '#000',
              padding: '10px 0px'
            }}
          >
            Escolha como pagar
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}>
            <PaymentOptionsContainer>
              <RadioGroup 
                value={selectedPayment}
                onChange={handlePaymentChange}
              >
                <Box sx={{ height: '73px' }}>
                  <PaymentOptionHeader>
                    <PaymentMethodInfo>
                      <Radio value="credit_card" />
                      <PaymentLabel>
                        <CreditCardIcon sx={{ color: '#666', fontSize: 24 }} />
                        <Typography sx={{ 
                          fontSize: '16px',
                          fontFamily: 'Inter, sans-serif',
                          color: '#333'
                        }}>
                          Cartão de crédito
                        </Typography>
                      </PaymentLabel>
                    </PaymentMethodInfo>
                  </PaymentOptionHeader>
                </Box>

                {selectedPayment === 'credit_card' && (
                  <PaymentFormContainer>
                    <FormInputContainer>
                      <Box>
                        <CustomInputLabel>Número do cartão</CustomInputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& input': {
                                padding: '13px 13px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '.375em'
                              }
                            }
                          }}
                        />
                      </Box>
                      <Box>
                        <CustomInputLabel>Nome impresso no cartão</CustomInputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& input': {
                                padding: '13px 13px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '.375em'
                              }
                            }
                          }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: '16px' }}>
                        <Box sx={{ flex: 1 }}>
                          <CustomInputLabel>Data de vencimento</CustomInputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                              sx: {
                                '& input': {
                                  padding: '13px 13px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderRadius: '.375em'
                                }
                              }
                            }}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <CustomInputLabel>Código de segurança</CustomInputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                              sx: {
                                '& input': {
                                  padding: '13px 13px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderRadius: '.375em'
                                }
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <CustomInputLabel>CPF do titular do cartão</CustomInputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& input': {
                                padding: '13px 13px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '.375em'
                              }
                            }
                          }}
                        />
                      </Box>
                      <Box>
                        <CustomInputLabel>Parcelas</CustomInputLabel>
                        <FormControl fullWidth size="small">
                          <Select
                            value="1"
                            sx={{
                              '& .MuiSelect-select': {
                                padding: '13px 13px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '.375em'
                              }
                            }}
                          >
                            <MenuItem value="1">1x de R$ {cart.total.toFixed(2)} sem juros</MenuItem>
                            <MenuItem value="2">2x de R$ {(cart.total / 2).toFixed(2)} sem juros</MenuItem>
                            <MenuItem value="3">3x de R$ {(cart.total / 3).toFixed(2)} sem juros</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </FormInputContainer>
                  </PaymentFormContainer>
                )}

                <Divider sx={{ margin: 0 }} />

                <Box sx={{ height: '73px' }}>
                  <PaymentOptionHeader>
                    <PaymentMethodInfo>
                      <Radio value="pix" />
                      <PaymentLabel>
                        <QrCodeIcon sx={{ color: '#666', fontSize: 24 }} />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ 
                            fontSize: '16px',
                            fontFamily: 'Inter, sans-serif',
                            color: '#333'
                          }}>
                            PIX
                          </Typography>
                          <InstantApproval>
                            Aprovado na hora
                          </InstantApproval>
                        </Box>
                      </PaymentLabel>
                    </PaymentMethodInfo>
                  </PaymentOptionHeader>
                </Box>
              </RadioGroup>
            </PaymentOptionsContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <ContinueButton
                variant="contained"
                onClick={() => {}}
                sx={{ width: '220px' }}
              >
                Continuar
              </ContinueButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} sx={{ 
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
          maxWidth: '412px !important',
          marginLeft: '0px !important',
          marginTop: '0px !important'
        }}>
          <OrderSummaryContainer>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 600,
                fontFamily: 'Inter, sans-serif',
                color: '#000',
              }}
            >
              Resumo da compra
            </Typography>

            <Divider />

            <SummaryRow sx={{ mb: 2 }}>
              <Typography>Produto</Typography>
              <Typography>R$ {cart.total.toFixed(2)}</Typography>
            </SummaryRow>

            <SummaryRow>
              <Typography>Frete</Typography>
              <Typography>R$ 15,29</Typography>
            </SummaryRow>

            <Divider />

            <TotalRow>
              <Typography>Você pagará</Typography>
              <Typography>R$ {(parseFloat(cart.total) + 15.29).toFixed(2)}</Typography>
            </TotalRow>
          </OrderSummaryContainer>
        </Grid>
      </Grid>
    );
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'email':
        return renderEmailStep();
      case 'shipping':
        return renderShippingStep();
      case 'shippingOptions':
        return renderShippingOptionsStep();
      case 'payment':
        return renderPaymentStep();
      default:
        return renderEmailStep();
    }
  };

  return (
    <>
      <HeaderRow>
        <Logo
          src="https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/6.6.5/mercadolibre/pt_logo_large_plus@2x.webp"
          alt="Mercado Livre"
        />
      </HeaderRow>
      
      {currentStep === 'email' ? (
        <EmailStepContainer>
          {renderEmailStep()}
        </EmailStepContainer>
      ) : (
        <OtherStepsContainer>
          {renderCurrentStep()}
        </OtherStepsContainer>
      )}
    </>
  );
};

export default MercadoLivreCheckout;
