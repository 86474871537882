import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Card,
  CardContent,
  CardMedia,
  InputAdornment
} from '@mui/material';
import { Plus, Settings, Eye, EyeOff } from 'react-feather';
import { getGatewayApiKeys, updateGatewayApiKey } from '../services/api';

// Font styles
const interFont = {
  fontFamily: '"Inter", sans-serif',
};

// Theme colors based on existing components
const getThemeColors = (theme) => ({
  background: theme.palette.mode === 'dark' ? '#1A1D1F' : '#ffffff',
  primary: '#29ae60',
  text: {
    primary: theme.palette.mode === 'dark' ? '#fff' : '#1A1D1F',
    secondary: theme.palette.mode === 'dark' ? '#9A9FA5' : '#6F767E',
  },
  status: {
    active: '#22C55E',     // Green color for active status
    inactive: '#EF4444',   // Red color for inactive status
    unsupported: '#F59E0B' // Yellow color for unsupported status
  }
});

// Status dot component
const StatusDot = ({ status }) => {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        bgcolor: colors.status[status],
      }}
    />
  );
};

// Mock data for development
const mockGateways = [
  {
    id: 1,
    gateway: 'mundpay',
    affiliation_name: 'Mundpay Pagamentos',
    active: false,
    apiKey: '',
    maskedApiKey: '',
    logo: '/mundpay-logo.png',
    supported: true
  },
  {
    id: 2,
    gateway: 'mercadopago',
    affiliation_name: 'Mercado Pago',
    active: false,
    apiKey: '',
    maskedApiKey: '',
    logo: '/mercadopago-logo.png',
    supported: true
  },
  {
    id: 3,
    gateway: 'hotmart',
    affiliation_name: 'Hotmart',
    active: false,
    apiKey: '',
    maskedApiKey: '',
    logo: '/hotmart-logo.png',
    supported: false,
    comingSoon: true
  },
  {
    id: 4,
    gateway: 'stripe',
    affiliation_name: 'Stripe Payments',
    active: false,
    apiKey: '',
    maskedApiKey: '',
    logo: '/stripe-logo.png',
    supported: false,
    comingSoon: true
  }
];

const availableGateways = [
  {
    id: 1,
    name: 'mundpay',
    logo: '/mundpay-logo.png'
  },
  {
    id: 2,
    name: 'mercadopago',
    logo: '/mercadopago-logo.png'
  },
  {
    id: 3,
    name: 'hotmart',
    logo: '/hotmart-logo.png'
  },
  {
    id: 4,
    name: 'stripe',
    logo: '/stripe-logo.png'
  }
];

const Integrations = () => {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  const [gateways, setGateways] = useState(mockGateways);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newGatewayModalOpen, setNewGatewayModalOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);

  useEffect(() => {
    const loadApiKeys = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await getGatewayApiKeys();
        
        // Update gateways with API keys from backend
        const updatedGateways = gateways.map(gateway => {
          const apiKeyData = response.data.api_keys.find(
            key => key.gateway === gateway.gateway
          );
          
          return {
            ...gateway,
            active: Boolean(apiKeyData?.api_key),
            apiKey: apiKeyData?.api_key || ''
          };
        });
        
        setGateways(updatedGateways);
      } catch (error) {
        console.error('Error loading API keys:', error.response || error);
        setError(error.response?.data?.detail || 'Failed to load API keys');
      } finally {
        setLoading(false);
      }
    };

    loadApiKeys();
  }, []);

  const handleNewGateway = () => {
    setNewGatewayModalOpen(true);
  };

  const handleCloseNewGatewayModal = () => {
    setNewGatewayModalOpen(false);
  };

  const handleSelectGateway = (gateway) => {
    setSelectedGateway({
      gateway: gateway.name,
      affiliation_name: `${gateway.name} Payments`,
      apiKey: ''
    });
    setNewGatewayModalOpen(false);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setSelectedGateway(null);
    setApiKey('');
    setShowApiKey(false);
  };

  const handleEdit = (gateway) => {
    setSelectedGateway(gateway);
    setApiKey(gateway.apiKey || '');
    setShowApiKey(false);
    setEditModalOpen(true);
  };

  const handleSaveCredentials = async () => {
    if (!selectedGateway) return;

    try {
      setError(null);
      await updateGatewayApiKey(selectedGateway.gateway, apiKey.trim());
      
      const updatedGateways = gateways.map(gateway => 
        gateway.id === selectedGateway.id
          ? {
              ...gateway,
              apiKey: apiKey.trim(),
              maskedApiKey: '••••••••••••',
              active: Boolean(apiKey.trim())
            }
          : gateway
      );

      setGateways(updatedGateways);
      handleCloseModal();
    } catch (error) {
      console.error('Error saving API key:', error.response || error);
      setError(error.response?.data?.detail || 'Failed to save API key');
    }
  };

  const renderApiKeyCell = (gateway) => {
    return (
      <Box
        onClick={() => (gateway.gateway === 'mundpay' || gateway.gateway === 'mercadopago') && handleEdit(gateway)}
        sx={{
          cursor: (gateway.gateway === 'mundpay' || gateway.gateway === 'mercadopago') ? 'pointer' : 'not-allowed',
          color: (gateway.gateway === 'mundpay' || gateway.gateway === 'mercadopago') ? colors.text.secondary : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
          '&:hover': {
            color: (gateway.gateway === 'mundpay' || gateway.gateway === 'mercadopago') ? colors.primary : undefined,
          }
        }}
      >
        <Typography>
          {!gateway.active 
            ? 'Configurar API Key'
            : '••••••••••••'
          }
        </Typography>
      </Box>
    );
  };

  // Get count of gateways with API keys
  const activeGatewaysCount = gateways.filter(g => Boolean(g.apiKey?.trim())).length;

  if (loading) {
    return (
      <Box sx={{ p: '30px', display: 'flex', justifyContent: 'center' }}>
        <Typography>Carregando...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: '30px', display: 'flex', justifyContent: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: '30px', bgcolor: colors.background, minHeight: '100vh' }}>
      {/* Header Row */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography variant="h4" sx={{ ...interFont, fontWeight: 600, color: colors.text.primary, mb: 1 }}>
              Integrações
            </Typography>
            <Typography sx={{ ...interFont, color: colors.text.secondary }}>
              {activeGatewaysCount} gateways ativos
            </Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<Plus size={20} />}
            onClick={handleNewGateway}
            sx={{
              bgcolor: colors.primary,
              '&:hover': { bgcolor: colors.primary },
              textTransform: 'none',
              borderRadius: '10px',
              ...interFont,
            }}
          >
            Novo Gateway
          </Button>
        </Box>
      </Box>

      {/* Gateway List Row */}
      <Box>
        <TableContainer component={Paper} sx={{ borderRadius: '10px', boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gateway</TableCell>
                <TableCell>Nome da Afiliação</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>API Key</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gateways.map((gateway) => (
                <TableRow
                  key={gateway.id}
                  onMouseEnter={() => setHoveredRow(gateway.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  sx={{
                    '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)' },
                  }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <img 
                        src={gateway.logo} 
                        alt={gateway.gateway} 
                        style={{ 
                          width: 32, 
                          height: 32,
                          opacity: gateway.gateway === 'mundpay' || gateway.gateway === 'mercadopago' ? 1 : 0.5
                        }} 
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ color: colors.text.primary }}>
                          {gateway.gateway}
                        </Typography>
                        {gateway.gateway !== 'mundpay' && gateway.gateway !== 'mercadopago' && (
                          <Typography 
                            sx={{ 
                              color: colors.text.secondary,
                              fontSize: '0.75rem',
                              bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                              px: 1,
                              py: 0.25,
                              borderRadius: '4px',
                              display: 'inline-flex',
                              alignItems: 'center',
                              height: '20px'
                            }}
                          >
                            Em breve
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{gateway.affiliation_name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StatusDot 
                        status={
                          gateway.gateway !== 'mundpay' && gateway.gateway !== 'mercadopago'
                            ? 'unsupported'
                            : gateway.active
                              ? 'active'
                              : 'inactive'
                        } 
                      />
                      <Typography 
                        sx={{ 
                          color: gateway.gateway !== 'mundpay' && gateway.gateway !== 'mercadopago'
                            ? colors.status.unsupported
                            : gateway.active
                              ? colors.status.active
                              : colors.status.inactive
                        }}
                      >
                        {gateway.gateway !== 'mundpay' && gateway.gateway !== 'mercadopago'
                          ? 'Não suportado'
                          : gateway.active
                            ? 'Ativo'
                            : 'Inativo'
                        }
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {renderApiKeyCell(gateway)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* New Gateway Selection Modal */}
      <Dialog 
        open={newGatewayModalOpen} 
        onClose={handleCloseNewGatewayModal}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.mode === 'dark' ? '#1A1D1F' : '#FFFFFF',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '800px',
          }
        }}
      >
        <DialogTitle sx={{ pt: 3, px: 3 }}>
          <Typography variant="h6" sx={{ ...interFont, color: colors.text.primary, fontWeight: 600, mb: 1 }}>
            Selecione o gateway para adicionar
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {availableGateways.map((gateway) => (
              <Grid item xs={3} key={gateway.id}>
                <Card 
                  onClick={() => handleSelectGateway(gateway)}
                  sx={{
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: theme.palette.mode === 'dark' 
                        ? '0 4px 20px rgba(255, 255, 255, 0.1)'
                        : '0 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                    bgcolor: theme.palette.mode === 'dark' ? '#1E2124' : '#FFFFFF',
                    border: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                  }}
                >
                  <CardContent sx={{ textAlign: 'center', p: 2 }}>
                    <CardMedia
                      component="img"
                      image={gateway.logo}
                      alt={gateway.name}
                      sx={{
                        width: '80%',
                        height: 'auto',
                        margin: '0 auto 16px',
                        objectFit: 'contain'
                      }}
                    />
                    <Typography sx={{ ...interFont, color: colors.text.primary }}>
                      {gateway.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* API Key Edit Modal */}
      <Dialog 
        open={editModalOpen} 
        onClose={() => {
          handleCloseModal();
          setShowApiKey(false);
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            width: '100%',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{ ...interFont, pb: 1 }}>
          Configurar API Key
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ ...interFont, color: colors.text.secondary, mb: 2 }}>
              Configure a API Key para {selectedGateway?.gateway}
            </Typography>
            <Box sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                label="API Key"
                type={showApiKey ? "text" : "password"}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowApiKey(!showApiKey)}
                        edge="end"
                      >
                        {showApiKey ? <EyeOff size={16} /> : <Eye size={16} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button 
            onClick={() => {
              handleCloseModal();
              setShowApiKey(false);
            }}
            sx={{ 
              color: colors.text.secondary,
              textTransform: 'none',
              ...interFont
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSaveCredentials();
              setShowApiKey(false);
            }}
            sx={{
              bgcolor: colors.primary,
              '&:hover': { bgcolor: colors.primary },
              textTransform: 'none',
              borderRadius: '10px',
              ...interFont
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Integrations;
