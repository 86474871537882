import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { ProductForm } from '../components/Products';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

function NewProduct() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleClose = () => {
    navigate('/products');
  };

  const handleSubmit = async (productData) => {
    try {
      // Format the price to a number if it's a string
      const formattedData = {
        ...productData,
        price: typeof productData.price === 'string' 
          ? parseFloat(productData.price.replace(/[^\d.]/g, ''))
          : productData.price
      };

      console.log('Submitting data:', formattedData);
      
      const response = await api.post('/products/', formattedData);
      console.log('Response:', response);

      if (response.status === 201) {
        navigate('/products');
      }
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        console.error('Error creating product:', err);
        console.error('Error response:', err.response?.data);
      }
    }
  };

  return (
    <Box>
      <ProductForm
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        initialData={null}
      />
    </Box>
  );
}

export default NewProduct;
