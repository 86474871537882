import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Button, Avatar, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import api from '../services/api';
import BadgeIcon from '@mui/icons-material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Profile = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewUrl2, setPreviewUrl2] = useState(null);
  const [previewUrl3, setPreviewUrl3] = useState(null);
  const [previewUrl4, setPreviewUrl4] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragging2, setIsDragging2] = useState(false);
  const [isDragging3, setIsDragging3] = useState(false);
  const [isDragging4, setIsDragging4] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('/user/profile/');
        setUserData(response.data);
      } catch (error) {
        console.error('Erro ao carregar dados do usuário:', error);
      }
    };

    fetchUserData();
  }, []);

  const formatDocument = (doc) => {
    if (!doc) return '';
    if (doc.length === 11) {
      return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (doc.length === 14) {
      return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return doc;
  };

  const formatWhatsApp = (number) => {
    if (!number) return '';
    return number.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('document_file', file);

    try {
      await api.put('/user/profile/', {
        profile: {
          document_file: file
        }
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Refresh user data after upload
      const response = await api.get('/user/profile/');
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao fazer upload do documento:', error);
    }
  };

  const getVerificationStatusColor = (status) => {
    switch (status) {
      case 'pendente':
        return '#DC2626'; // Red for pending
      case 'enviada':
        return '#D97706'; // Orange for sent
      case 'aceita':
        return '#059669'; // Green for accepted
      default:
        return '#DC2626';
    }
  };

  const getVerificationStatusText = (status) => {
    switch (status) {
      case 'pendente':
        return 'Documentação pendente';
      case 'enviada':
        return 'Documentação enviada';
      case 'aceita':
        return 'Documentação aceita';
      default:
        return 'Documentação pendente';
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const getFileNameFromUrl = (url) => {
    if (!url) return '';
    return url.split('/').pop();
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = getFileNameFromUrl(url);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Erro ao baixar o documento:', error);
    }
  };

  const handleDragEnter = (e, inputNumber) => {
    e.preventDefault();
    e.stopPropagation();
    if (inputNumber === 1) {
      setIsDragging(true);
    } else if (inputNumber === 2) {
      setIsDragging2(true);
    } else if (inputNumber === 3) {
      setIsDragging3(true);
    } else {
      setIsDragging4(true);
    }
  };

  const handleDragLeave = (e, inputNumber) => {
    e.preventDefault();
    e.stopPropagation();
    if (inputNumber === 1) {
      setIsDragging(false);
    } else if (inputNumber === 2) {
      setIsDragging2(false);
    } else if (inputNumber === 3) {
      setIsDragging3(false);
    } else {
      setIsDragging4(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, inputNumber) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (inputNumber === 1) {
      setIsDragging(false);
    } else if (inputNumber === 2) {
      setIsDragging2(false);
    } else if (inputNumber === 3) {
      setIsDragging3(false);
    } else {
      setIsDragging4(false);
    }

    const files = [...e.dataTransfer.files];
    
    if (files.length > 0) {
      handleFileSelect(files[0], inputNumber);
    }
  };

  const handleFileSelect = (file, inputNumber) => {
    if (file) {
      if (inputNumber === 1) {
        setSelectedFile(file);
      } else if (inputNumber === 2) {
        setSelectedFile2(file);
      } else if (inputNumber === 3) {
        setSelectedFile3(file);
      } else {
        setSelectedFile4(file);
      }
    }
  };

  const handleSubmitDocuments = () => {
    if (selectedFile && selectedFile2 && selectedFile3 && selectedFile4) {
      setIsApproved(true);
    }
  };

  if (!userData) {
    return <Typography sx={{ color: theme.palette.text.primary }}>Carregando...</Typography>;
  }

  return (
    <Box sx={{ 
      p: { xs: 2, md: 3.75 }, 
      maxWidth: 1200, 
      margin: '0 auto',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default
    }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4, 
          fontWeight: 600,
          fontSize: '24px',
          color: theme.palette.text.primary
        }}
      >
        Olá, {userData.full_name}!
      </Typography>

      <Grid container spacing={4}>
        {/* Left Column - User Info */}
        <Grid item xs={12} md={5}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              height: '100%',
              borderRadius: '10px',
              backgroundColor: theme.palette.background.paper,
              border: '1px solid #eeeeee'
            }}
          >
            {/* User Profile Header */}
            <Box sx={{ p: 2.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar 
                  sx={{ 
                    width: 64, 
                    height: 64, 
                    bgcolor: theme.palette.primary.main,
                    fontSize: '24px',
                    fontWeight: 500,
                    mr: 2
                  }}
                >
                  {getInitials(userData.full_name)}
                </Avatar>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme.palette.text.primary,
                      fontSize: '18px',
                      fontWeight: 600,
                      mb: 0.5
                    }}
                  >
                    {userData.full_name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px'
                    }}
                  >
                    {userData.email}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Information Section */}
            <Box sx={{ p: 2.5, borderTop: '1px solid #E5E7EB' }}>
              {/* User Information List */}
              <Box sx={{ mb: 4 }}>
                {/* Row 1: Verification Status */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 2
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    Verificação
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: getVerificationStatusColor(userData.profile?.documentation_status),
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {getVerificationStatusText(userData.profile?.documentation_status)}
                  </Typography>
                </Box>

                {/* Row 2: Document */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 2
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    {userData.profile?.person_type === 'pj' ? 'CNPJ' : 'CPF'}
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {userData.profile?.person_type === 'pj' 
                      ? formatDocument(userData.profile?.cnpj)
                      : formatDocument(userData.profile?.cpf)}
                  </Typography>
                </Box>

                {/* Row 3: WhatsApp */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center'
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    WhatsApp
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {formatWhatsApp(userData.profile?.whatsapp)}
                  </Typography>
                </Box>
              </Box>

              <Button
                variant="outlined"
                fullWidth
                startIcon={<PersonIcon />}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  borderColor: theme.palette.divider,
                  color: theme.palette.text.primary,
                  fontSize: '14px',
                  fontWeight: 500,
                  p: '10px 16px',
                  '&:hover': {
                    borderColor: theme.palette.action.hover,
                    backgroundColor: theme.palette.action.selected,
                  },
                }}
              >
                Configurar Perfil
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Document Upload */}
        <Grid item xs={12} md={7}>
          <Paper 
            elevation={0}
            sx={{ 
              p: isApproved ? 0 : 3.5, 
              height: '100%',
              borderRadius: '10px',
              backgroundColor: theme.palette.background.paper,
              border: '1px solid #eeeeee'
            }}
          >
            <Box sx={{ p: 0 }}>
              {isApproved ? (
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  py: 4,
                  bgcolor: '#e6f7d9',
                  borderRadius: 2
                }}>
                  <Box component="img" src="/accountapproved.svg" sx={{ width: 280, height: 'auto', mb: 3 }} />
                  <Typography sx={{ 
                    color: '#111827',
                    fontSize: '18px',
                    fontWeight: 500,
                    maxWidth: '80%'
                  }}>
                    Documentos aprovados na análise. Sua conta está aprovada!
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="h6" sx={{ color: '#111827', mb: 3 }}>
                    Documentos
                  </Typography>

                  {/* First File Upload */}
                  {selectedFile && (
                    <Typography sx={{ 
                      color: '#111827', 
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: 1 
                    }}>
                      RG (frente)
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: '2px dashed',
                      borderColor: selectedFile ? '#27AE60' : (isDragging ? '#27AE60' : '#E5E7EB'),
                      borderRadius: '12px',
                      p: 2,
                      backgroundColor: selectedFile ? '#27ae601a' : (isDragging ? '#27ae601a' : 'transparent'),
                      cursor: 'pointer',
                      mb: 3,
                    }}
                    onDragEnter={(e) => handleDragEnter(e, 1)}
                    onDragLeave={(e) => handleDragLeave(e, 1)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 1)}
                    onClick={() => document.getElementById('fileInput').click()}
                  >
                    {!selectedFile ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                          <BadgeIcon sx={{ fontSize: 40, color: '#9CA3AF' }} />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ color: '#111827', fontWeight: 500, mb: 0.5 }}>
                            Upload do RG (frente)
                          </Typography>
                          <Typography sx={{ color: '#6B7280', fontSize: '14px' }}>
                            Aceito PDF e PNG
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        p: 1.5,
                      }}>
                        <CheckCircleIcon sx={{ color: '#27AE60', fontSize: 20, mr: 1 }} />
                        <Typography sx={{ color: '#27AE60', fontSize: '14px' }}>
                          {selectedFile.name} anexado!
                        </Typography>
                      </Box>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileSelect(e.target.files[0], 1)}
                      accept=".pdf,.png"
                    />
                  </Box>

                  {/* Second File Upload */}
                  {selectedFile2 && (
                    <Typography sx={{ 
                      color: '#111827', 
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: 1 
                    }}>
                      RG (verso)
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: '2px dashed',
                      borderColor: selectedFile2 ? '#27AE60' : (isDragging2 ? '#27AE60' : '#E5E7EB'),
                      borderRadius: '12px',
                      p: 2,
                      backgroundColor: selectedFile2 ? '#27ae601a' : (isDragging2 ? '#27ae601a' : 'transparent'),
                      cursor: 'pointer',
                      mb: 3,
                    }}
                    onDragEnter={(e) => handleDragEnter(e, 2)}
                    onDragLeave={(e) => handleDragLeave(e, 2)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 2)}
                    onClick={() => document.getElementById('fileInput2').click()}
                  >
                    {!selectedFile2 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                          <BadgeIcon sx={{ fontSize: 40, color: '#9CA3AF' }} />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ color: '#111827', fontWeight: 500, mb: 0.5 }}>
                            Upload do RG (verso)
                          </Typography>
                          <Typography sx={{ color: '#6B7280', fontSize: '14px' }}>
                            Aceito PDF e PNG
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        p: 1.5,
                      }}>
                        <CheckCircleIcon sx={{ color: '#27AE60', fontSize: 20, mr: 1 }} />
                        <Typography sx={{ color: '#27AE60', fontSize: '14px' }}>
                          {selectedFile2.name} anexado!
                        </Typography>
                      </Box>
                    )}
                    <input
                      type="file"
                      id="fileInput2"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileSelect(e.target.files[0], 2)}
                      accept=".pdf,.png"
                    />
                  </Box>

                  {/* Third File Upload */}
                  {selectedFile3 && (
                    <Typography sx={{ 
                      color: '#111827', 
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: 1 
                    }}>
                      Selfie com documento
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: '2px dashed',
                      borderColor: selectedFile3 ? '#27AE60' : (isDragging3 ? '#27AE60' : '#E5E7EB'),
                      borderRadius: '12px',
                      p: 2,
                      backgroundColor: selectedFile3 ? '#27ae601a' : (isDragging3 ? '#27ae601a' : 'transparent'),
                      cursor: 'pointer',
                      mb: 3,
                    }}
                    onDragEnter={(e) => handleDragEnter(e, 3)}
                    onDragLeave={(e) => handleDragLeave(e, 3)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 3)}
                    onClick={() => document.getElementById('fileInput3').click()}
                  >
                    {!selectedFile3 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                          <BadgeIcon sx={{ fontSize: 40, color: '#9CA3AF' }} />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ color: '#111827', fontWeight: 500, mb: 0.5 }}>
                            Upload selfie com documento
                          </Typography>
                          <Typography sx={{ color: '#6B7280', fontSize: '14px' }}>
                            Aceito PDF e PNG
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        p: 1.5,
                      }}>
                        <CheckCircleIcon sx={{ color: '#27AE60', fontSize: 20, mr: 1 }} />
                        <Typography sx={{ color: '#27AE60', fontSize: '14px' }}>
                          {selectedFile3.name} anexado!
                        </Typography>
                      </Box>
                    )}
                    <input
                      type="file"
                      id="fileInput3"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileSelect(e.target.files[0], 3)}
                      accept=".pdf,.png"
                    />
                  </Box>

                  {/* Fourth File Upload */}
                  {selectedFile4 && (
                    <Typography sx={{ 
                      color: '#111827', 
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: 1 
                    }}>
                      Contrato social
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: '2px dashed',
                      borderColor: selectedFile4 ? '#27AE60' : (isDragging4 ? '#27AE60' : '#E5E7EB'),
                      borderRadius: '12px',
                      p: 2,
                      backgroundColor: selectedFile4 ? '#27ae601a' : (isDragging4 ? '#27ae601a' : 'transparent'),
                      cursor: 'pointer',
                      mb: 3,
                    }}
                    onDragEnter={(e) => handleDragEnter(e, 4)}
                    onDragLeave={(e) => handleDragLeave(e, 4)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 4)}
                    onClick={() => document.getElementById('fileInput4').click()}
                  >
                    {!selectedFile4 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ mr: 2 }}>
                          <BadgeIcon sx={{ fontSize: 40, color: '#9CA3AF' }} />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ color: '#111827', fontWeight: 500, mb: 0.5 }}>
                            Upload contrato social
                          </Typography>
                          <Typography sx={{ color: '#6B7280', fontSize: '14px' }}>
                            Aceito PDF e PNG
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        p: 1.5,
                      }}>
                        <CheckCircleIcon sx={{ color: '#27AE60', fontSize: 20, mr: 1 }} />
                        <Typography sx={{ color: '#27AE60', fontSize: '14px' }}>
                          {selectedFile4.name} anexado!
                        </Typography>
                      </Box>
                    )}
                    <input
                      type="file"
                      id="fileInput4"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileSelect(e.target.files[0], 4)}
                      accept=".pdf,.png"
                    />
                  </Box>

                  {/* Submit Button */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitDocuments}
                      sx={{
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                        height: '40px',
                        bgcolor: '#111827',
                        color: '#fff',
                        textTransform: 'none',
                        fontSize: '14px',
                        borderRadius: '10px',
                        px: 4,
                        '&:hover': {
                          bgcolor: '#1f2937',
                        },
                      }}
                    >
                      Enviar documentos
                    </Button>
                    {(!selectedFile || !selectedFile2 || !selectedFile3 || !selectedFile4) && (
                      <Typography sx={{ color: '#6B7280', fontSize: '12px', mt: 1 }}>
                        * Anexe todos os documentos para continuar
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
