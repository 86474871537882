import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ProductForm } from '../components/Products';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { logout } = useAuth();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}/`);
        console.log('Fetched product:', response.data);
        setProduct(response.data);
      } catch (err) {
        if (err.response?.status === 401) {
          logout();
        } else {
          console.error('Error fetching product:', err);
          navigate('/products');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, logout, navigate]);

  const handleClose = () => {
    navigate('/products');
  };

  const handleSubmit = async (productData) => {
    try {
      // Format the price to a number if it's a string
      const formattedData = {
        ...productData,
        price: typeof productData.price === 'string' 
          ? parseFloat(productData.price.replace(/[^\d.]/g, ''))
          : productData.price
      };

      console.log('Submitting data:', formattedData);
      
      const response = await api.put(`/products/${id}/`, formattedData);
      console.log('Response:', response);
      
      if (response.status === 200) {
        navigate('/products');
      }
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        console.error('Error updating product:', err);
        console.error('Error response:', err.response?.data);
      }
    }
  };

  if (loading) {
    return null; // Or a loading spinner
  }

  return (
    <Box>
      <ProductForm
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        initialData={product}
      />
    </Box>
  );
}

export default EditProduct;
