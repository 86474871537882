import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, Paper, Stack, CircularProgress, Modal } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatCurrency } from '../utils/format';

/**
 * PixCheckout is a React component that renders a checkout page for PIX payments.
 * It includes a PIX code for copying, a countdown timer until the payment expires,
 * and displays the details of the purchase. It also handles copying the PIX code
 * to the clipboard and shows a modal notification upon successful copy.
 * 
 * The component utilizes URL parameters to fetch and display purchase information.
 * If the necessary payment information is not present in the location state, it
 * displays a message indicating that the payment information was not found.
 */
const PixCheckout = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const qrCodeFetched = useRef(false);
  const { 
    productTitle, 
    productPrice, 
    selectedOffers, 
    totalPrice, 
    fullName, 
    formData, 
    pixCopiaECola, 
    payment_id,
    qrCodeImage  
  } = location.state || {};
  const [copied, setCopied] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutes in seconds
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [progressWidth, setProgressWidth] = useState(100);
  const [checkingPayment, setCheckingPayment] = useState(true);
  const [qrCodeBase64, setQrCodeBase64] = useState(qrCodeImage || '');  
  const [qrCodeError, setQrCodeError] = useState('');
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    let timer;
    if (openSnackbar) {
      setProgressWidth(100);
      const startTime = Date.now();
      const duration = 4000; // 4 seconds

      timer = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const remaining = Math.max(0, 100 - (elapsed / duration) * 100);
        setProgressWidth(remaining);

        if (elapsed >= duration) {
          clearInterval(timer);
          setOpenSnackbar(false);
          setProgressWidth(100);
        }
      }, 16); // ~60fps
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [openSnackbar]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) return 0;
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchQRCode = async () => {
      if (!payment_id || qrCodeFetched.current || qrCodeImage) return;  
      
      qrCodeFetched.current = true;
      console.log('[DEBUG] Fetching QR code for payment_id:', payment_id);
      
      try {
        const response = await fetch(`/api/payments/${payment_id}/qrcode/`);
        const data = await response.json();
        
        if (data.error) {
          console.log('[DEBUG] QR code fetch error:', data.error);
          setQrCodeError(data.error);
        } else if (data.qrcode_base64) {  
          console.log('[DEBUG] QR code fetch successful');
          setQrCodeBase64(data.qrcode_base64);
        }
      } catch (error) {
        console.error('[DEBUG] QR code fetch failed:', error);
        setQrCodeError('Failed to load QR code');
      }
    };

    console.log('[DEBUG] useEffect triggered for QR code fetch');
    fetchQRCode();
  }, [payment_id, qrCodeImage]);

  useEffect(() => {
    let paymentCheckTimer;
    
    const checkPaymentStatus = async () => {
      try {
        const response = await fetch(`/api/payments/${payment_id}/status/`);
        const data = await response.json();
        
        if (data.is_paid) {
          clearInterval(paymentCheckTimer);
          navigate(`/checkout/${id}/confirmacao`, {
            state: {
              productTitle,
              productPrice,
              selectedOffers,
              totalPrice,
              formData,
              payment_id
            }
          });
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    };

    if (checkingPayment && payment_id) {
      // Initial check
      checkPaymentStatus();
      // Set up interval for subsequent checks
      paymentCheckTimer = setInterval(checkPaymentStatus, 10000); // Check every 10 seconds
    }

    return () => {
      if (paymentCheckTimer) {
        clearInterval(paymentCheckTimer);
      }
    };
  }, [checkingPayment, payment_id, navigate, productTitle, productPrice, selectedOffers, totalPrice, formData]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const handleCopyPixCode = async () => {
    if (!pixCopiaECola) {
      console.error('Pix code not found');
      return;
    }
    
    try {
      await navigator.clipboard.writeText(pixCopiaECola);
    } catch (err) {
      const textArea = document.createElement('textarea');
      textArea.value = pixCopiaECola;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
      
      document.body.removeChild(textArea);
    }
    
    setCopied(true);
    setOpenSnackbar(true);
    setCheckingPayment(true);
    setTimeout(() => {
      setCopied(false);
      setOpenSnackbar(false);
    }, 4000); // 4 seconds
  };

  const handleConfirmPayment = async () => {
    try {
      const response = await fetch(`/api/payments/${payment_id}/status/`);
      const data = await response.json();
      
      if (data.is_paid) {
        navigate(`/checkout/${id}/confirmacao`, {
          state: { 
            fullName,
            productTitle,
            productPrice,
            selectedOffers,
            totalPrice,
            formData, 
            payment_id
          }
        });
      } else {
        setPaymentError('Pagamento ainda não foi recebido, tente novamente em alguns instantes');
        setTimeout(() => {
          setPaymentError('');
        }, 5000); // Clear error after 5 seconds
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setPaymentError('Erro ao verificar o pagamento, tente novamente');
      setTimeout(() => {
        setPaymentError('');
      }, 5000); // Clear error after 5 seconds
    }
  };

  if (!location.state) {
    return (
      <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
        <Typography variant="h5">
          Informações do pagamento não encontradas
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      maxWidth: 480, 
      margin: '0 auto', 
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,

    }}>
      {/* Header */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <CircularProgress size={40} sx={{ mb: 2 }} />
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
          Falta pouco, {fullName}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ px: 4 }}>
          Assim que o pagamento via PIX for confirmado, te avisaremos.
        </Typography>
      </Box>

      {/* PIX Section */}
      <Paper sx={{ p: 3, borderRadius: '10px' }}>
        <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 600, mb: 1 }}>
          Aqui está o PIX copia e cola
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 3, fontSize: '15px'}}>
          Copie o código ou use a câmera para ler o QR Code e realize o pagamento no app do seu banco.
        </Typography>

        {/* PIX Code */}
        <Box sx={{ 
          bgcolor: '#f8fafc',
          p: 2,
          borderRadius: '10px',
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid #e2e8f0'
        }}>
          <Typography sx={{ 
            flex: 1,
            mr: 2,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '300px',
            fontFamily: 'monospace',
            fontSize: '14px',
            color: '#64748b'
          }}>
            {pixCopiaECola}
          </Typography>
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyPixCode}
            sx={{
              bgcolor: '#22c55e',
              color: '#fff',
              '&:hover': {
                bgcolor: '#16a34a'
              },
              textTransform: 'none',
              borderRadius: '10px',
              px: 2
            }}
          >
            {copied ? 'Copiado' : 'Copiar'}
          </Button>
        </Box>

        {/* QR Code */}
        {qrCodeError ? (
          <Typography color="error" sx={{ textAlign: 'center', mb: 2 }}>
            {qrCodeError}
          </Typography>
        ) : qrCodeBase64 ? (
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            mb: 2
          }}>
            <Box sx={{ 
              width: '200px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#f8fafc',
              borderRadius: '10px',
              border: '1px solid #e2e8f0',
              p: 2
            }}>
              <img 
                src={qrCodeBase64}
                alt="QR Code PIX"
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
              />
            </Box>
          </Box>
        ) : null}

        {/* Confirm Payment Button */}
        <Button
          onClick={handleConfirmPayment}
          variant="contained"
          fullWidth
          disabled={false}  
          sx={{
            bgcolor: '#22c55e',
            color: '#fff',
            '&:hover': {
              bgcolor: '#16a34a'
            },
            textTransform: 'none',
            borderRadius: '10px',
            py: 1.5,
            mt: 2
          }}
        >
          CONFIRMAR PAGAMENTO
        </Button>

        {/* Timer */}
        <Box sx={{ 
          bgcolor: '#f8fafc',
          p: 2,
          borderRadius: '10px',
          textAlign: 'center',
          border: '1px solid #e2e8f0',
          mt: 3
        }}>
          <Typography variant="body2" sx={{ fontSize: '15px' }} color="text.secondary">
            Faltam <Box component="span" sx={{ fontWeight: 700, color: '#000' }}>{formatTime(timeLeft)}</Box> minutos para o pagamento expirar...
          </Typography>
          <Box sx={{ 
            width: '100%', 
            height: 6, 
            bgcolor: '#e2e8f0',
            mt: 1,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden'
          }}>
            <Box sx={{ 
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: `${(timeLeft / (10 * 60)) * 100}%`,
              bgcolor: '#22c55e',
              borderRadius: 2,
              transition: 'width 1s linear'
            }} />
          </Box>
        </Box>

        {/* Payment Error Message */}
        {paymentError && (
          <Typography 
            color="error" 
            sx={{ 
              textAlign: 'center', 
              mt: 2,
              fontSize: '14px'
            }}
          >
            {paymentError}
          </Typography>
        )}
      </Paper>

      {/* Instructions */}
      <Paper sx={{ p: 3, borderRadius: '10px' }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Para realizar o pagamento:
        </Typography>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography sx={{ color: '#64748b', fontWeight: 600 }}>1</Typography>
            <Typography>Abra o aplicativo do seu banco;</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography sx={{ color: '#64748b', fontWeight: 600 }}>2</Typography>
            <Typography>Escolha a opção PIX e cole o código ou use a câmera do celular para pagar com QR Code;</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography sx={{ color: '#64748b', fontWeight: 600 }}>3</Typography>
            <Typography>Confirme as informações e finalize o pagamento.</Typography>
          </Box>
        </Stack>
      </Paper>

      {/* Order Summary */}
      <Paper sx={{ p: 3, borderRadius: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ShoppingBagOutlinedIcon />
            <Typography sx={{ fontWeight: 600 }}>Sua Compra</Typography>
          </Box>
          <Typography sx={{ fontWeight: 600 }}>{formatCurrency(totalPrice)}</Typography>
        </Box>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
            <VerifiedUserIcon sx={{ color: '#64748b', mt: 0.5 }} />
            <Box>
              <Typography>{productTitle}</Typography>
              <Typography variant="body2" color="text.secondary">
                {formatCurrency(productPrice)}
              </Typography>
            </Box>
          </Box>
          {selectedOffers?.map((offer, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
              <VerifiedUserIcon sx={{ color: '#64748b', mt: 0.5 }} />
              <Box>
                <Typography>{offer.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatCurrency(offer.price)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Paper>

      {/* Footer */}
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <a href="#" style={{ color: '#64748b', textDecoration: 'none', fontSize: '13px', marginRight: '8px' }}>
            Termos de uso
          </a>
          <a href="#" style={{ color: '#64748b', textDecoration: 'none', fontSize: '13px' }}>
            Política de privacidade
          </a>
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontSize: '13px' }}>
          Tecnologia Clavo Pay & Checkout 2025
        </Typography>
        <Typography variant="body2" sx={{ color: '#22c55e', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5, fontSize: '13px' }}>
          <VerifiedUserIcon sx={{ fontSize: 16 }} />
          Compra 100% segura
        </Typography>
      </Box>

      {/* Copy Modal */}
      <Modal
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        aria-labelledby="copy-modal"
        aria-describedby="copy-success-message"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'relative',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          outline: 'none',
          maxWidth: 400,
          mx: 2,
          textAlign: 'center',
          overflow: 'hidden' // Ensure progress line stays within border radius
        }}>
          {/* Content Container */}
          <Box sx={{ p: 4, pb: 3 }}>
            <Box sx={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              bgcolor: '#22c55e20',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto 24px'
            }}>
              <CheckCircleIcon sx={{ color: '#22c55e', fontSize: 40 }} />
            </Box>
            <Typography variant="h5" component="h2" sx={{ 
              color: '#475569',
              fontWeight: 600,
              mb: 2
            }}>
              Copiado!
            </Typography>
            <Typography sx={{ 
              color: '#64748b',
              mb: 3,
              fontSize: '16px'
            }}>
              Acesse o aplicativo bancário, cole a chave pix e realize o pagamento
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setOpenSnackbar(false)}
              sx={{
                bgcolor: '#22c55e',
                color: '#fff',
                '&:hover': {
                  bgcolor: '#22c55e'
                },
                textTransform: 'none',
                borderRadius: '10px',
                py: 1.5,
                fontSize: '16px',
                fontWeight: 600
              }}
            >
              OK
            </Button>
          </Box>
          {/* Progress Line */}
          <Box sx={{
            position: 'relative',
            width: '100%',
            height: 3,
            bgcolor: '#e2e8f0'
          }}>
            <Box sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: `${progressWidth}%`,
              bgcolor: '#22c55e',
              borderRadius: 2,
              transition: 'width 16ms linear'
            }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PixCheckout;
