import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  styled,
  Chip,
  Button,
  Drawer,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// Import card flags
import visaIcon from '../assets/visa.svg';
import mastercardIcon from '../assets/mastercard.svg';
import amexIcon from '../assets/amex.svg';
import eloIcon from '../assets/elo.svg';
import hiperIcon from '../assets/hiper.svg';
import creditcardIcon from '../assets/creditcard.svg';

// Card flag images
const cardFlags = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  amex: amexIcon,
  elo: eloIcon,
  hipercard: hiperIcon,
  generic: creditcardIcon,
};

const getCardFlag = (number) => {
  const cleanNumber = number.replace(/\D/g, '');
  
  // Visa
  if (cleanNumber.match(/^4[0-9]{12}(?:[0-9]{3})?$/)) {
    return 'visa';
  }
  
  // Mastercard
  if (cleanNumber.match(/^5[1-5][0-9]{14}$/)) {
    return 'mastercard';
  }
  
  // American Express
  if (cleanNumber.match(/^3[47][0-9]{13}$/)) {
    return 'amex';
  }
  
  // Elo
  if (cleanNumber.match(/^(636368|438935|504175|451416|636297|5067|4576|4011)/)) {
    return 'elo';
  }
  
  // Hipercard
  if (cleanNumber.match(/^(606282|3841)[0-9]{10}$/)) {
    return 'hipercard';
  }
  
  return 'generic';
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#374151' : '#F9FAFB',
    borderRadius: '10px',
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#4B5563' : '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#6B7280' : '#D1D5DB',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#6B7280' : '#D1D5DB',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 16px',
    '&::placeholder': {
      color: '#9CA3AF',
      opacity: 1,
    },
  },
}));

const CardContainer = styled(Box)({
  perspective: '1000px',
  width: '100%',
  maxWidth: '400px',
  height: '220px',
  marginBottom: '32px',
});

const CardInner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFlipped',
})(({ isFlipped }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
}));

const CardSide = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  borderRadius: '20px',
  padding: '24px',
  background: 'linear-gradient(135deg, #7209b7 0%, #3a0ca3 30%, #4361ee 60%, #4cc9f0 100%)',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
});

const CardFront = styled(CardSide)({
  transform: 'rotateY(0)',
});

const CardBack = styled(CardSide)({
  transform: 'rotateY(180deg)',
  background: 'linear-gradient(135deg, #4cc9f0 0%, #4361ee 40%, #3a0ca3 70%, #7209b7 100%)',
});

const MagneticStrip = styled(Box)({
  width: '100%',
  height: '40px',
  background: '#2D3748',
  marginTop: '20px',
});

const CVVStrip = styled(Box)({
  background: '#fff',
  padding: '10px',
  marginTop: '20px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const CVVText = styled(Typography)({
  color: '#1F2937',
  fontFamily: 'monospace',
  letterSpacing: '2px',
});

const CardNumber = styled(Typography)({
  fontSize: '24px',
  letterSpacing: '2px',
  marginBottom: '24px',
  fontFamily: 'monospace',
});

const CardInfo = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

const Financial = () => {
  const theme = useTheme();
  const [cardData, setCardData] = useState({
    cardNumber: '',
    name: '',
    expiry: '',
    cvv: '',
  });
  const [taxData, setTaxData] = useState({
    type: 'company',
    document: '',
    name: '',
  });
  const [savedTaxData, setSavedTaxData] = useState(null);
  const [errors, setErrors] = useState({
    cardNumber: '',
    name: '',
    expiry: '',
    cvv: '',
  });
  const [taxErrors, setTaxErrors] = useState({
    document: '',
    name: '',
  });
  const [selectedCard, setSelectedCard] = useState('');
  const [isFlipped, setIsFlipped] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaxModalOpen, setIsTaxModalOpen] = useState(false);
  const [savedCards, setSavedCards] = useState(() => {
    const storedCards = localStorage.getItem('savedCards');
    return storedCards ? JSON.parse(storedCards) : [];
  });

  useEffect(() => {
    const storedCards = localStorage.getItem('savedCards');
    const cards = storedCards ? JSON.parse(storedCards) : [];
    setSavedCards(cards);
    // Select first card if there are cards and none is selected
    if (cards.length > 0 && !selectedCard) {
      setSelectedCard(cards[0].id.toString());
    }
  }, []);

  useEffect(() => {
    // Update localStorage when cards change
    localStorage.setItem('savedCards', JSON.stringify(savedCards));
    // Select first card if there are cards and none is selected
    if (savedCards.length > 0 && !selectedCard) {
      setSelectedCard(savedCards[0].id.toString());
    }
  }, [savedCards]);

  const validateCardNumber = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    if (!cleanNumber) {
      return 'Número do cartão é obrigatório';
    }
    if (!getCardFlag(cleanNumber)) {
      return 'Número do cartão inválido';
    }
    if (cleanNumber.length < 13 || cleanNumber.length > 19) {
      return 'Número do cartão deve ter entre 13 e 19 dígitos';
    }
    // Luhn algorithm for card number validation
    let sum = 0;
    let isEven = false;
    for (let i = cleanNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cleanNumber[i]);
      if (isEven) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      isEven = !isEven;
    }
    if (sum % 10 !== 0) {
      return 'Número do cartão inválido';
    }
    return '';
  };

  const validateName = (name) => {
    if (!name) {
      return 'Nome é obrigatório';
    }
    if (name.length < 3) {
      return 'Nome deve ter pelo menos 3 caracteres';
    }
    if (!/^[a-zA-ZÀ-ÿ\s]*$/.test(name)) {
      return 'Nome deve conter apenas letras';
    }
    if (!/\s/.test(name)) {
      return 'Digite o nome completo';
    }
    return '';
  };

  const validateExpiry = (expiry) => {
    if (!expiry) {
      return 'Data de validade é obrigatória';
    }
    const [month, year] = expiry.split('/');
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;
    
    if (!month || !year || month.length !== 2 || year.length !== 2) {
      return 'Data inválida (MM/AA)';
    }
    
    const monthNum = parseInt(month);
    const yearNum = parseInt(year);
    
    if (monthNum < 1 || monthNum > 12) {
      return 'Mês inválido';
    }
    
    if (yearNum < currentYear || (yearNum === currentYear && monthNum < currentMonth)) {
      return 'Cartão vencido';
    }
    
    if (yearNum > currentYear + 20) {
      return 'Data de validade muito distante';
    }
    
    return '';
  };

  const validateCVV = (cvv) => {
    if (!cvv) {
      return 'CVV é obrigatório';
    }
    if (!/^\d{3,4}$/.test(cvv)) {
      return 'CVV deve ter 3 ou 4 dígitos';
    }
    return '';
  };

  const handleCardNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length > 0) {
      value = value.match(/.{1,4}/g).join(' ');
    }
    if (value.length <= 19) {
      setCardData({ ...cardData, cardNumber: value });
      setErrors({ ...errors, cardNumber: validateCardNumber(value) });
    }
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setCardData({ ...cardData, name: value });
    setErrors({ ...errors, name: validateName(value) });
  };

  const handleExpiryChange = (event) => {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length > 0) {
      if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2, 4);
      }
      if (value.length <= 5) {
        setCardData({ ...cardData, expiry: value });
        setErrors({ ...errors, expiry: validateExpiry(value) });
      }
    } else {
      setCardData({ ...cardData, expiry: value });
      setErrors({ ...errors, expiry: validateExpiry(value) });
    }
  };

  const handleCVVChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      setCardData({ ...cardData, cvv: value });
      setErrors({ ...errors, cvv: validateCVV(value) });
    }
  };

  const handleCVVFocus = () => {
    setIsFlipped(true);
  };

  const handleCVVBlur = () => {
    setIsFlipped(false);
  };

  const handleSaveCard = () => {
    // Check card limit
    if (savedCards.length >= 3) {
      return;
    }

    // Validate all fields
    const newErrors = {
      cardNumber: validateCardNumber(cardData.cardNumber),
      name: validateName(cardData.name),
      expiry: validateExpiry(cardData.expiry),
      cvv: validateCVV(cardData.cvv),
    };

    setErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    const newCard = {
      ...cardData,
      id: Date.now(),
      lastFourDigits: cardData.cardNumber.slice(-4),
      flag: getCardFlag(cardData.cardNumber),
    };

    setSavedCards([...savedCards, newCard]);
    setIsModalOpen(false);
    setCardData({
      cardNumber: '',
      name: '',
      expiry: '',
      cvv: '',
    });
    setErrors({
      cardNumber: '',
      name: '',
      expiry: '',
      cvv: '',
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCardData({
      cardNumber: '',
      name: '',
      expiry: '',
      cvv: '',
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCard = (cardId) => {
    setSavedCards(savedCards.filter(card => card.id !== cardId));
  };

  const handleOpenTaxModal = () => {
    setIsTaxModalOpen(true);
    setTaxData({
      type: 'company',
      document: '',
      name: '',
    });
  };

  const handleCloseTaxModal = () => {
    setIsTaxModalOpen(false);
  };

  const validateDocument = (document, type) => {
    const cleanDoc = document.replace(/\D/g, '');
    
    if (!cleanDoc) {
      return type === 'company' ? 'CNPJ é obrigatório' : 'CPF é obrigatório';
    }

    if (type === 'company') {
      if (cleanDoc.length !== 14) {
        return 'CNPJ deve ter 14 dígitos';
      }

      // CNPJ validation
      let sum = 0;
      let pos = 5;
      
      for (let i = 0; i < 12; i++) {
        sum += parseInt(cleanDoc[i]) * pos--;
        if (pos < 2) pos = 9;
      }
      
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result !== parseInt(cleanDoc[12])) {
        return 'CNPJ inválido';
      }
      
      sum = 0;
      pos = 6;
      
      for (let i = 0; i < 13; i++) {
        sum += parseInt(cleanDoc[i]) * pos--;
        if (pos < 2) pos = 9;
      }
      
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result !== parseInt(cleanDoc[13])) {
        return 'CNPJ inválido';
      }
    } else {
      if (cleanDoc.length !== 11) {
        return 'CPF deve ter 11 dígitos';
      }

      // CPF validation
      if (/^(\d)\1{10}$/.test(cleanDoc)) {
        return 'CPF inválido';
      }

      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanDoc[i]) * (10 - i);
      }
      
      let result = 11 - (sum % 11);
      if (result > 9) result = 0;
      
      if (result !== parseInt(cleanDoc[9])) {
        return 'CPF inválido';
      }
      
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanDoc[i]) * (11 - i);
      }
      
      result = 11 - (sum % 11);
      if (result > 9) result = 0;
      
      if (result !== parseInt(cleanDoc[10])) {
        return 'CPF inválido';
      }
    }

    return '';
  };

  const formatDocument = (value, type) => {
    const cleanValue = value.replace(/\D/g, '');
    if (type === 'company') {
      // Format: XX.XXX.XXX/XXXX-XX
      return cleanValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    } else {
      // Format: XXX.XXX.XXX-XX
      return cleanValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
  };

  const validateTaxName = (name, type) => {
    if (!name) {
      return type === 'company' ? 'Razão social é obrigatória' : 'Nome é obrigatório';
    }
    if (name.length < 3) {
      return 'Nome deve ter pelo menos 3 caracteres';
    }
    return '';
  };

  const handleSaveTaxData = () => {
    const newErrors = {
      document: validateDocument(taxData.document, taxData.type),
      name: validateTaxName(taxData.name, taxData.type),
    };

    setTaxErrors(newErrors);

    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    // Save the tax data
    setSavedTaxData({
      ...taxData,
      id: Date.now(),
    });
    setIsTaxModalOpen(false);
  };

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '100%',
      bgcolor: '#fff',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{ 
        p: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        flex: 1,
      }}>
        {/* Header */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1
        }}>
          <Box>
            <Typography variant="h4" sx={{ 
              color: theme.palette.mode === 'dark' ? '#fff' : '#111827',
              fontSize: '24px',
              fontWeight: 600,
              mb: 1
            }}>
              Financeiro
            </Typography>
          </Box>
        </Box>

        {/* Content */}
        <Grid container spacing={3}>
          {/* Left Column - Payment Data */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0}
              sx={{ 
                p: '24px',
                bgcolor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
                borderRadius: '12px',
                border: '1px solid #eeeeee',
                height: '100%',
              }}
            >
              <Typography variant="h6" sx={{ 
                color: theme.palette.mode === 'dark' ? '#fff' : '#111827',
                fontSize: '22px',
                fontWeight: 600,
                mb: 3
              }}>
                Dados de Pagamento
              </Typography>

              {/* Header with status */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ 
                    color: '#6B7280',
                    fontSize: '14px',
                    fontWeight: 700,
                  }}>
                    Cartão de crédito
                  </Typography>
                  <Chip 
                    label={savedCards.length > 0 ? "Ativo" : "Pendente"}
                    sx={{ 
                      backgroundColor: savedCards.length > 0 ? '#DEF7EC' : '#FEE2E2',
                      color: savedCards.length > 0 ? '#03543F' : '#EF4444',
                      fontWeight: 500,
                      fontSize: '14px',
                      height: '28px',
                      borderRadius: '4px',
                    }} 
                  />
                </Box>
                <Typography variant="body2" sx={{ 
                  color: '#6B7280',
                  fontSize: '14px',
                  mb: 2,
                }}>
                  {savedCards.length > 0 
                    ? "Cartões cadastrados para eventuais cobranças"
                    : "Cadastre um cartão de crédito válido para eventuais cobranças"
                  }
                </Typography>

                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleOpenModal}
                  disabled={savedCards.length >= 3}
                  sx={{
                    height: '40px',
                    backgroundColor: '#111827',
                    color: '#fff',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 500,
                    px: 3,
                    borderRadius: '10px',
                    mb: 3,
                    '&:hover': {
                      backgroundColor: '#374151',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#E5E7EB',
                      color: '#9CA3AF',
                    }
                  }}
                >
                  {savedCards.length >= 3 ? 'Limite máximo de cartões atingido' : 'Adicionar Cartão'}
                </Button>

                {/* Card List */}
                {savedCards.length > 0 && (
                  <Box sx={{ mb: 3 }}>
                    <RadioGroup
                      value={selectedCard}
                      onChange={(e) => setSelectedCard(e.target.value)}
                      sx={{ width: '100%' }}
                    >
                      {savedCards.map((card) => (
                        <Box
                          key={card.id}
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            mb: 2,
                            border: '1px solid #E5E7EB',
                            borderRadius: '10px',
                            backgroundColor: '#F9FAFB',
                            cursor: 'pointer',
                            '&:hover': {
                              borderColor: '#9CA3AF',
                            },
                          }}
                          onClick={() => setSelectedCard(card.id.toString())}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Radio 
                              checked={selectedCard === card.id.toString()}
                              value={card.id.toString()}
                              sx={{ 
                                color: '#9CA3AF',
                                padding: '4px',
                                '&.Mui-checked': {
                                  color: '#111827',
                                },
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            <Box
                              sx={{
                                width: 48,
                                height: 32,
                                backgroundColor: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img 
                                src={cardFlags[card.flag]} 
                                alt={`${card.flag} logo`}
                                style={{ 
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain'
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#111827' }}>
                                •••• •••• •••• {card.lastFourDigits}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', color: '#6B7280' }}>
                                {card.name} • Expira em {card.expiry}
                              </Typography>
                            </Box>
                            <IconButton
                              size="small"
                              sx={{ 
                                position: 'absolute',
                                right: 16,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#6B7280',
                                '&:hover': {
                                  color: '#EF4444',
                                  bgcolor: 'rgba(239, 68, 68, 0.04)'
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteCard(card.id);
                              }}
                            >
                              <DeleteOutlineIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Right Column - Tax Information */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0}
              sx={{ 
                p: '24px',
                bgcolor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
                borderRadius: '12px',
                border: '1px solid #eeeeee',
                height: '100%',
              }}
            >
              <Typography variant="h6" sx={{ 
                color: theme.palette.mode === 'dark' ? '#fff' : '#111827',
                fontSize: '22px',
                fontWeight: 600,
                mb: 3
              }}>
                Dados tributários
              </Typography>

              {/* Header with status */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ 
                    color: '#6B7280',
                    fontSize: '14px',
                    fontWeight: 700,
                  }}>
                    Dados fiscais
                  </Typography>
                  <Chip 
                    label={savedTaxData ? "Ativo" : "Pendente"}
                    sx={{ 
                      backgroundColor: savedTaxData ? '#DCFCE7' : '#FEE2E2',
                      color: savedTaxData ? '#16A34A' : '#EF4444',
                      fontWeight: 500,
                      fontSize: '14px',
                      height: '28px',
                      borderRadius: '4px',
                    }} 
                  />
                </Box>
                <Typography variant="body2" sx={{ 
                  color: '#6B7280',
                  fontSize: '14px',
                  mb: 2,
                }}>
                  Acrescente os seus dados de pessoa física ou empresa para emissão de nota fiscal
                </Typography>

                {/* Add Tax Data Button - Only show if no data saved */}
                {!savedTaxData && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleOpenTaxModal}
                    sx={{
                      height: '40px',
                      backgroundColor: '#111827',
                      color: '#fff',
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: 500,
                      px: 3,
                      borderRadius: '10px',
                      mb: 3,
                      '&:hover': {
                        backgroundColor: '#374151',
                      },
                    }}
                  >
                    Adicionar dados fiscais
                  </Button>
                )}

                {/* Display Saved Tax Data */}
                {savedTaxData && (
                  <Box
                    sx={{
                      p: 2,
                      border: '1px solid #E5E7EB',
                      borderRadius: '10px',
                      backgroundColor: '#F9FAFB',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#111827', mb: 1 }}>
                        {savedTaxData.type === 'company' ? 'Empresa' : 'Individual'}
                      </Typography>
                      <Typography sx={{ fontSize: '14px', color: '#6B7280', mb: 0.5 }}>
                        {savedTaxData.type === 'company' ? 'CNPJ: ' : 'CPF: '}
                        {savedTaxData.document}
                      </Typography>
                      <Typography sx={{ fontSize: '14px', color: '#6B7280' }}>
                        {savedTaxData.type === 'company' ? 'Razão social: ' : 'Nome: '}
                        {savedTaxData.name}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Bottom Column - Billing Information */}
          <Grid item xs={12}>
            <Paper 
              elevation={0}
              sx={{ 
                p: '24px',
                bgcolor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
                borderRadius: '12px',
                border: '1px solid #eeeeee',
              }}
            >
              <Typography variant="h6" sx={{ 
                color: theme.palette.mode === 'dark' ? '#fff' : '#111827',
                fontSize: '22px',
                fontWeight: 600,
                mb: 2
              }}>
                Sua próxima fatura
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography sx={{ 
                    fontSize: '32px',
                    fontWeight: 600,
                    color: '#111827',
                  }}>
                    R$ 0,00
                  </Typography>
                  <VisibilityIcon sx={{ color: '#6B7280', fontSize: 20 }} />
                </Box>
                <Typography sx={{ 
                  color: '#6B7280',
                  fontSize: '14px',
                }}>
                  Acumulado de taxas resultante das suas vendas.
                </Typography>
              </Box>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ 
                      p: 1,
                      borderRadius: '8px',
                      backgroundColor: '#F3F4F6',
                    }}>
                      <CalendarTodayIcon sx={{ color: '#6B7280', fontSize: 20 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ 
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#111827',
                        mb: 0.5
                      }}>
                        Cobrança realizada 2x ao dia
                      </Typography>
                      <Typography sx={{ 
                        fontSize: '14px',
                        color: '#6B7280',
                      }}>
                        Ou ao atingir o limite de pagamento
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ 
                      p: 1,
                      borderRadius: '8px',
                      backgroundColor: '#F3F4F6',
                    }}>
                      <PaymentIcon sx={{ color: '#6B7280', fontSize: 20 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ 
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#111827',
                        mb: 0.5
                      }}>
                        R$ 100,00
                      </Typography>
                      <Typography sx={{ 
                        fontSize: '14px',
                        color: '#6B7280',
                      }}>
                        Limite de pagamento
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Typography sx={{ 
                mt: 3,
                color: '#6B7280',
                fontSize: '14px',
              }}>
                <Typography component="span" sx={{ 
                  color: '#111827',
                  fontWeight: 500,
                }}>
                  Como você paga:
                </Typography>
                {' '}
                Descontado no cartão.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Sliding Modal */}
      <Drawer
        anchor="left"
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px',
            p: 3,
            bgcolor: '#fff',
          }
        }}
      >
        <Box sx={{ width: '100%' }}>
          {/* Modal Header */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 4,
          }}>
            <Typography variant="h6" sx={{ 
              fontSize: '18px',
              fontWeight: 600,
              color: '#111827',
            }}>
              Adicionar novo cartão
            </Typography>
            <IconButton onClick={handleCloseModal} sx={{ color: '#6B7280' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Credit Card Display */}
          <CardContainer>
            <CardInner isFlipped={isFlipped}>
              {/* Front of Card */}
              <CardFront>
                <Box>
                  <Typography variant="caption" sx={{ opacity: 0.8, mb: 1, display: 'block' }}>
                    Número do cartão
                  </Typography>
                  <CardNumber>
                    {cardData.cardNumber || '•••• •••• •••• ••••'}
                  </CardNumber>
                </Box>
                <CardInfo>
                  <Box>
                    <Typography variant="caption" sx={{ opacity: 0.8, mb: 1, display: 'block' }}>
                      Nome
                    </Typography>
                    <Typography variant="body1">
                      {cardData.name || 'Digite o nome'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" sx={{ opacity: 0.8, mb: 1, display: 'block' }}>
                      Data de validade
                    </Typography>
                    <Typography variant="body1">
                      {cardData.expiry || 'MM/AA'}
                    </Typography>
                  </Box>
                </CardInfo>
              </CardFront>

              {/* Back of Card */}
              <CardBack>
                <Box sx={{ height: '40px' }} />
                <MagneticStrip />
                <CVVStrip>
                  <CVVText>
                    {cardData.cvv || '•••'}
                  </CVVText>
                </CVVStrip>
              </CardBack>
            </CardInner>
          </CardContainer>

          {/* Card Form */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 4 }}>
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
                Número do cartão
              </Typography>
              <StyledTextField
                fullWidth
                placeholder="•••• •••• •••• ••••"
                value={cardData.cardNumber}
                onChange={handleCardNumberChange}
                inputProps={{ maxLength: 19 }}
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
              />
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
                Nome completo no cartão
              </Typography>
              <StyledTextField
                fullWidth
                placeholder="Digite o nome"
                value={cardData.name}
                onChange={handleNameChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
                  Data de validade
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="MM/AA"
                  value={cardData.expiry}
                  onChange={handleExpiryChange}
                  error={!!errors.expiry}
                  helperText={errors.expiry}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" size="small">
                          <HelpOutlineIcon sx={{ color: '#9CA3AF' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
                  Código de segurança
                </Typography>
                <StyledTextField
                  fullWidth
                  placeholder="•••"
                  value={cardData.cvv}
                  onChange={handleCVVChange}
                  onFocus={handleCVVFocus}
                  onBlur={handleCVVBlur}
                  error={!!errors.cvv}
                  helperText={errors.cvv}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" size="small">
                          <HelpOutlineIcon sx={{ color: '#9CA3AF' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleSaveCard}
              sx={{
                mt: 2,
                height: '48px',
                backgroundColor: '#111827',
                color: '#fff',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#374151',
                },
              }}
            >
              Salvar cartão
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Tax Data Modal */}
      <Drawer
        anchor="right"
        open={isTaxModalOpen}
        onClose={handleCloseTaxModal}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px',
            p: 3,
            bgcolor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
          }
        }}
      >
        {/* Modal Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4,
        }}>
          <Typography variant="h6" sx={{ 
            color: theme.palette.mode === 'dark' ? '#fff' : '#111827',
            fontSize: '18px',
            fontWeight: 600,
          }}>
            Informações tributárias
          </Typography>
          <IconButton onClick={handleCloseTaxModal}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography sx={{ 
          color: '#6B7280',
          fontSize: '14px',
          mb: 3,
        }}>
          Selecione uma modalidade de venda:
        </Typography>

        {/* Tax Type Selection */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={6}>
            <Box
              onClick={() => {
                if (taxData.type !== 'company') {
                  setTaxData({
                    type: 'company',
                    document: '',
                    name: '',
                  });
                  setTaxErrors({
                    document: '',
                    name: '',
                  });
                }
              }}
              sx={{
                p: 2,
                border: '1px solid',
                borderColor: taxData.type === 'company' ? '#27AE60' : '#E5E7EB',
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: taxData.type === 'company' ? '#27ae601a' : 'transparent',
                '&:hover': {
                  borderColor: '#27AE60',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Radio
                  checked={taxData.type === 'company'}
                  sx={{
                    color: '#9CA3AF',
                    '&.Mui-checked': {
                      color: '#27AE60',
                    },
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#111827' }}>
                    Empresa
                  </Typography>
                  <Typography sx={{ fontSize: '14px', color: '#6B7280' }}>
                    Pessoa jurídica
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              onClick={() => {
                if (taxData.type !== 'individual') {
                  setTaxData({
                    type: 'individual',
                    document: '',
                    name: '',
                  });
                  setTaxErrors({
                    document: '',
                    name: '',
                  });
                }
              }}
              sx={{
                p: 2,
                border: '1px solid',
                borderColor: taxData.type === 'individual' ? '#27AE60' : '#E5E7EB',
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: taxData.type === 'individual' ? '#27ae601a' : 'transparent',
                '&:hover': {
                  borderColor: '#27AE60',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Radio
                  checked={taxData.type === 'individual'}
                  sx={{
                    color: '#9CA3AF',
                    '&.Mui-checked': {
                      color: '#27AE60',
                    },
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#111827' }}>
                    Individual
                  </Typography>
                  <Typography sx={{ fontSize: '14px', color: '#6B7280' }}>
                    Pessoa física
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Tax Form */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
              {taxData.type === 'company' ? 'CNPJ' : 'CPF'}
            </Typography>
            <StyledTextField
              fullWidth
              placeholder={taxData.type === 'company' ? 'CNPJ da empresa' : 'CPF'}
              value={formatDocument(taxData.document, taxData.type)}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                if ((taxData.type === 'company' && value.length <= 14) || 
                    (taxData.type === 'individual' && value.length <= 11)) {
                  setTaxData({ ...taxData, document: value });
                  setTaxErrors({ ...taxErrors, document: validateDocument(value, taxData.type) });
                }
              }}
              error={!!taxErrors.document}
              helperText={taxErrors.document}
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: '#374151', fontWeight: 500 }}>
              {taxData.type === 'company' ? 'Razão social' : 'Nome completo'}
            </Typography>
            <StyledTextField
              fullWidth
              placeholder={taxData.type === 'company' ? 'Razão social' : 'Nome completo'}
              value={taxData.name}
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                setTaxData({ ...taxData, name: value });
                setTaxErrors({ ...taxErrors, name: validateTaxName(value, taxData.type) });
              }}
              error={!!taxErrors.name}
              helperText={taxErrors.name}
              inputProps={{
                style: { textTransform: 'uppercase' }
              }}
            />
          </Box>
        </Box>

        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          onClick={handleSaveTaxData}
          sx={{
            mt: 4,
            py: 1.5,
            backgroundColor: '#111827',
            color: '#fff',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#374151',
            },
          }}
        >
          Salvar dados fiscais
        </Button>
      </Drawer>
    </Box>
  );
};

export default Financial;
