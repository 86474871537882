import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Switch,
  Avatar,
  Drawer,
  Popover,
} from '@mui/material';
import {
  Grid,
  BarChart2,
  FileText,
  ShoppingBag,
  Package,
  MessageSquare,
  Monitor,
  HelpCircle,
  MessageCircle,
  User,
  Moon,
  Sun,
  LogOut,
  CreditCard,
} from 'react-feather';

const DRAWER_WIDTH = 280;

const Sidebar = ({ darkMode, setDarkMode }) => {
  const location = useLocation();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleProfileClose();
    logout();
  };

  const menuItems = [
    { 
      section: 'MAIN MENU',
      items: [
        { text: 'Dashboard', icon: Grid, path: '/' },
        { text: 'Produtos', icon: Package, path: '/products' },
        { text: 'Perfil', icon: User, path: '/profile' },
        { text: 'Financeiro', icon: CreditCard, path: '/financial' },
      ]
    },
    {
      section: '',
      items: []
    }
  ];

  const isActiveRoute = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          border: 'none',
          boxShadow: '0 0 30px rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      <Box
        sx={{
          width: DRAWER_WIDTH,
          height: '100%',
          bgcolor: darkMode ? '#1A1D1F' : '#fff',
          borderRight: darkMode ? '1px solid #2A2F34' : '1px solid #E8ECF3',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Logo */}
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', ml: 1 }}>
          <img src="/logo.svg" alt="Consist" style={{ height: 32 }} />
          <Typography 
            variant="h6" 
            sx={{ 
              ml: 1,
              fontWeight: 600,
              fontSize: '1.1rem',
              color: darkMode ? '#fff' : '#000',
            }}
          >
            Clavo Pay
          </Typography>
        </Box>

        {/* Menu Sections */}
        {menuItems.map((section, index) => (
          <Box key={index} sx={{ mt: index === 1 ? 'auto' : 0 }}>
            {section.section && (
              <Typography
                sx={{
                  px: 3,
                  py: 1,
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  color: darkMode ? '#9A9FA5' : '#8A94A6',
                  letterSpacing: '0.5px',
                }}
              >
                {section.section}
              </Typography>
            )}
            <List sx={{ px: 2 }}>
              {section.items.map((item) => (
                <ListItem
                  key={item.text}
                  component={Link}
                  to={item.path}
                  sx={{
                    borderRadius: '8px',
                    mb: 0.5,
                    color: isActiveRoute(item.path) ? '#fff' : darkMode ? '#9A9FA5' : '#8A94A6',
                    bgcolor: isActiveRoute(item.path) ? '#27AE60' : 'transparent',
                    '&:hover': {
                      bgcolor: isActiveRoute(item.path) ? '#27AE60' : darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F8F9FB',
                      color: isActiveRoute(item.path) ? '#fff' : darkMode ? '#9A9FA5' : '#8A94A6',
                    },
                  }}
                >
                  <ListItemIcon sx={{ 
                    minWidth: 40,
                    color: 'inherit',
                  }}>
                    {typeof item.icon === 'function' ? 
                      <item.icon /> : 
                      <item.icon size={20} />
                    }
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text} 
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontSize: '0.9rem',
                        fontWeight: 500,
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}

        {/* Dark Mode Toggle */}
        <Box sx={{ px: 2, mb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 2,
              pl: 1,
            }}
          >
            <Sun
              size={16}
              color={!darkMode ? '#27AE60' : '#8A94A6'}
              style={{ cursor: 'pointer' }}
              onClick={() => darkMode && setDarkMode(false)}
            />
            
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#F8F9FB',
                borderRadius: '32px',
                p: '4px',
                width: '64px',
                height: '32px',
                position: 'relative',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: darkMode ? '#2A2F34' : '#E8ECF3',
              }}
              onClick={() => setDarkMode(!darkMode)}
            >
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  bgcolor: '#fff',
                  position: 'absolute',
                  left: darkMode ? '36px' : '4px',
                  transition: 'left 0.2s',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
              />
            </Box>

            <Moon 
              size={16} 
              color={darkMode ? '#27AE60' : '#8A94A6'}
              style={{ cursor: 'pointer' }}
              onClick={() => !darkMode && setDarkMode(true)}
            />
          </Box>
        </Box>

        {/* User Profile */}
        <Box sx={{ px: 2, mb: 2 }}>
          <ListItem
            sx={{
              borderRadius: '8px',
              p: 1,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F8F9FB',
              },
            }}
            onClick={handleProfileClick}
          >
            <Avatar
              src={`https://ui-avatars.com/api/?name=${user?.full_name || user?.email || 'U'}&background=27AE60&color=fff`}
              sx={{ 
                width: 40, 
                height: 40, 
                mr: 2,
                bgcolor: '#27AE60',
              }}
            />
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  color: darkMode ? '#fff' : '#000',
                  fontSize: '0.9rem',
                }}
              >
                {user?.full_name || user?.email?.split('@')[0] || 'Usuário'}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: darkMode ? '#9A9FA5' : '#8A94A6',
                  fontSize: '0.8rem',
                }}
              >
                {user?.email || 'usuário@example.com'}
              </Typography>
            </Box>
          </ListItem>

          {/* Logout Popover */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleProfileClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                mt: -2,
                ml: 2,
                width: 220,
                bgcolor: darkMode ? '#1A1D1F' : '#fff',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                border: darkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                overflow: 'hidden',
              }
            }}
          >
            <Box sx={{ p: '16px' }}>
              {/* User Info in Popover */}
              <Box sx={{ mb: 2, px: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    color: darkMode ? '#fff' : '#000',
                    fontSize: '0.95rem',
                    mb: 0.5,
                  }}
                >
                  {user?.full_name || user?.email?.split('@')[0] || 'Usuário'}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: darkMode ? '#9A9FA5' : '#8A94A6',
                    fontSize: '0.85rem',
                  }}
                >
                  {user?.email || 'usuário@example.com'}
                </Typography>
              </Box>

              {/* Divider */}
              <Box
                sx={{
                  height: '1px',
                  bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                  mb: 2,
                }}
              />

              {/* Profile Link */}
              <ListItem
                component={Link}
                to="/profile"
                onClick={handleProfileClose}
                sx={{
                  borderRadius: '8px',
                  mb: 1,
                  color: darkMode ? '#fff' : '#000',
                  '&:hover': {
                    bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F8F9FB',
                  },
                }}
              >
                <ListItemIcon sx={{ 
                  color: 'inherit',
                  minWidth: 36,
                }}>
                  <User size={18} />
                </ListItemIcon>
                <ListItemText 
                  primary="Meu Perfil" 
                  primaryTypographyProps={{ 
                    fontSize: '0.9rem',
                  }}
                />
              </ListItem>

              {/* Logout Button */}
              <ListItem
                button
                onClick={handleLogoutClick}
                sx={{
                  borderRadius: '8px',
                  color: '#B72C2C',
                  bgcolor: 'rgba(183, 44, 44, 0.1)',
                  '&:hover': {
                    bgcolor: 'rgba(183, 44, 44, 0.15)',
                  },
                }}
              >
                <ListItemIcon sx={{ 
                  color: 'inherit',
                  minWidth: 36,
                }}>
                  <LogOut size={18} />
                </ListItemIcon>
                <ListItemText 
                  primary="Sair" 
                  primaryTypographyProps={{ 
                    fontSize: '0.9rem',
                    fontWeight: 500,
                  }}
                />
              </ListItem>
            </Box>
          </Popover>
        </Box>

        {/* Upgrade Card */}
        <Box
          sx={{
            mx: 2,
            mb: 2,
            p: 2,
            borderRadius: '12px',
            bgcolor: '#27AE60',
            color: '#fff',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -20,
              right: -20,
              width: 60,
              height: 60,
              borderRadius: '50%',
              bgcolor: '#FF9F43',
            }}
          />
          <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
            Fale com um GERENTE agora.
          </Typography>
          <button
            style={{
              background: '#fff',
              border: 'none',
              borderRadius: '6px',
              padding: '6px 12px',
              fontSize: '0.8rem',
              fontWeight: 500,
              cursor: 'pointer',
            }}
          >
            GERENTE
          </button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
