import React from 'react';
import { Box, Typography, Paper, Chip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { formatCurrency } from '../utils/format';

const ConfirmacaoPage = () => {
  const location = useLocation();
  const { 
    fullName, 
    productTitle, 
    productPrice, 
    selectedOffers, 
    totalPrice,
    formData,
    payment_id 
  } = location.state || {};
  
  const transactionId = payment_id || 'N/A';

  return (
    <Box sx={{ 
      maxWidth: 480, 
      margin: '0 auto', 
      padding: 2,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Header */}
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Box sx={{
          width: 80,
          height: 80,
          borderRadius: '50%',
          bgcolor: '#22c55e20',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto 24px'
        }}>
          <CheckCircleIcon sx={{ color: '#22c55e', fontSize: 40 }} />
        </Box>
        <Typography variant="h4" sx={{ fontWeight: 600, color: '#22c55e', mb: 2 }}>
          Pagamento aprovado
        </Typography>
      </Box>

      {/* Payment Details */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Pagamento
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Transação:</Typography>
            <Typography>{transactionId}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Método de pagamento:</Typography>
            <Typography>PIX</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Status do pagamento:</Typography>
            <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>Pago</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Parcelas:</Typography>
            <Typography>1</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Customer Data */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Seus dados de compra
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Nome:</Typography>
            <Typography>{fullName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">E-mail:</Typography>
            <Typography>{formData?.email}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Telefone:</Typography>
            <Typography>{formData?.whatsapp}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">CPF:</Typography>
            <Typography>{formData?.cpf}</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Seller Info */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Responsável pela venda
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">Nome:</Typography>
            <Typography>21321</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">E-mail:</Typography>
            <Typography>ggag@gmail.com</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Purchase Items */}
      <Paper sx={{ p: 3, borderRadius: '12px', mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Itens da sua compra
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Main Product */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box 
              component="div"
              sx={{ 
                width: 60, 
                height: 60, 
                bgcolor: '#f1f5f9',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ShoppingBagOutlinedIcon sx={{ color: '#64748b', fontSize: 30 }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 500 }}>{productTitle}</Typography>
                <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>
                  {formatCurrency(productPrice)}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Additional Offers */}
          {selectedOffers?.map((offer, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box 
                component="div"
                sx={{ 
                  width: 60, 
                  height: 60, 
                  bgcolor: '#f1f5f9',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ShoppingBagOutlinedIcon sx={{ color: '#64748b', fontSize: 30 }} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 500 }}>{offer.title}</Typography>
                  <Typography sx={{ color: '#22c55e', fontWeight: 500 }}>
                    {formatCurrency(offer.price)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>

      {/* Footer */}
      <Box sx={{ mt: 'auto', textAlign: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 2 }}>
          <Typography sx={{ color: '#64748b' }}>21321</Typography>
          <Chip 
            label="Suporte"
            size="small"
            sx={{ 
              bgcolor: '#22c55e20',
              color: '#22c55e',
              fontWeight: 500,
              borderRadius: '6px'
            }} 
          />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Em caso de dúvidas entre em contato
        </Typography>
        <Typography variant="body2" sx={{ color: '#22c55e', mb: 3 }}>
          Email: ggag@gmail.com
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Mundpay está processando este pedido à serviço de 21321. 
            Ao prosseguir você esta concordando com os{' '}
            <a href="#" style={{ color: '#6366f1', textDecoration: 'none' }}>
              termos de uso
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <PublicIcon sx={{ color: '#64748b' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmacaoPage;
